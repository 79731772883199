/**
 * This example component demonstrates react-in-angularjs. It is not used in the application.
 * @see https://github.com/xjpro/react-in-angularjs
 * @example
 * <organisation-example organization-id="organizationId"></organisation-example>
 */

import { angularize } from 'react-in-angularjs';

type Props = {
  organizationId: string;
}

const OrganisationExample = ({ organizationId }: Readonly<Props>) =>  {
  return (
    <b>This is organisation: {organizationId} </b>
  );
};

angularize(OrganisationExample, 'organisationExample', angular.module('uasApp'), {
  organizationId: '<'
});

export default OrganisationExample;
